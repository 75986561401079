if ($('.restore-button').length) {
  $('.restore-button').on('click', showSaveRestoreReasonModal);
}

if ($('.restore-reason-button').length) {
  $('.restore-reason-button').on('click', showRestoreReasonModal);
}

function showRestoreReasonModal() {
  $('#restore_project_description').text($(this).data('project-description'));
  $('#show-restore-reason').modal('show');

  getProject(this);
}

function showSaveRestoreReasonModal() {
  $('#modal-restore-reason').modal('show');
  $('#modal-restore-reason').attr('data-project-id', $(this).attr('data-project-id'));

  $('#send-reason').off();
  $('#send-reason').on('click', restore); 
}

function hideSaveRestoreReasonModal() {
  $('#modal-restore-reason').modal('hide');
}

function addErrorClass() {
  if (!$('#restore-reason-div').hasClass('has-error')) {
    $('#restore-reason-div').addClass('has-error');
  }
}

function removeErrorClass() {
  if ($('#restore-reason-div').hasClass('has-error')) {
    $('#restore-reason-div').removeClass('has-error');
  }
}

function restore() {
  if (!$('#restore_reason').val()) {
    addErrorClass();
    return;
  }

  removeErrorClass();

  const restoreReason = $('#restore_reason').val();
  const _token = $('input[name="_token"]').val();
  const form = {
    restoreReason,
    _token,
  };

  const id = $('#modal-restore-reason').attr('data-project-id');

  $('#status').show();
  $('#preloader').show();

  saveReason(form, id)
    .then((res) => {
      if (res.success === true) {
        hideSaveRestoreReasonModal();
        window.location.reload();
      }
    })
    .catch((err) => {
      addErrorClass();
      $('#status').hide();
      $('#preloader').hide();

      console.error(err);
    });
}

function saveReason(form, id) {
  return new Promise((resolve, reject) => {
    $.ajax({
      type: 'POST',
      dataType: 'JSON',
      url: `/projects/${id}/restore-reason`,
      data: form,
      success: (res) => {
        resolve(res);
      },
      error: (err) => {
        reject(err);
      },
    });
  });
}

function getProject(element){
  if($(this).data('project-histories')){
    setRowsReasons(JSON.parse($(this).data('project-histories')));
    return;
  }
  $.ajax({
    type: 'GET',
    dataType: 'JSON',
    url: '/projects/'+ $(element).data('project-id') + '/show',
    success: (res) => {
      $(this).attr('data-project-histories', JSON.stringify(res.project_histories));
      setRowsReasons(res.project_histories);
    },
    error: (err) =>{
      console.log(err);
    }
  });
}

function setRowsReasons(data){
      let row = '';
      let histories = data.filter(history => history.restore_reason);

      $.each(histories, function(){
        row += `<tr><td>${this.restore_reason}</td><td>${moment(this.created_at).format('DD/MM/Y')}</td></tr>`;
      });

      $('#show-restore-reason .modal-body table tbody').html(row);
}
