const pageCreate = window.location.href.indexOf('activities-managerial/create') > -1;
const pageEdit = window.location.pathname.replace(/([/0-9])/g,'') === "activities-managerialedit";

if(pageCreate || pageEdit){
    $(function(){
        $('.reference_date').datetimepicker({
            format:'MM/YYYY'
        });


        $('#allProjectActivityManagerial').on('change', function(){
            const deleted_at = $(this).prop('checked') ? '' : 'whereNull';
            const project = $('select#activities-managerial-project_id').selectpicker('val');
            $.ajax({
              type: 'GET',
              dataType:'JSON',
              url:'/activities-managerial/projects?'+ 'deleted_at='+ deleted_at,
              success: function(res) {
              
                  $('select#activities-managerial-project_id').html('');
                  $('select#activities-managerial-project_id').selectpicker('destroy');
                  $.each(res.data,function(){
                      $('select#activities-managerial-project_id').append('<option value="'+this.id+'">'+this.description+'</option>');
                  });
                
                  $('select#activities-managerial-project_id').selectpicker('render');
                  $('select#activities-managerial-project_id').selectpicker('val',project);
              },
              error: function(err){
                console.log(err);
              }
            });
        })
      });
}