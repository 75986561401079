if ($('#file-upload').length !== 0) {
  $(function () {
    $('#additive-file-upload').on('click', function () {
      initFileModal();
    });
  });
}

function initFileModal() {
  $('#additive_info').val('');
  $('input[name="additive_file"]').val('');
  $('#file_text').text('');

  $('#file-upload').modal('show');

  $('#additive_attach').off();

  $('#additive_attach').on('click', function () {
    $('input[name="additive_file"]').trigger('click');
  });

  $('.btn-upload-file-proposal-presented').off();

  $('.btn-upload-file-proposal-presented').on('click', saveFile);

  $('input[name="additive_file"]').on('change', function () {
    writeFileName($(this)[0].files[0]);
  });
}

function saveFile() {
  if (formIsValid()) {
    const form = new FormData();

    form.append('_token', $('input[name="_token"]').val());

    if ($('input[name="id"]').val()) {
      form.append('commercial_proposal_id', $('input[name="id"]').val());
    }

    const originalFile = $('input[name="additive_file"]')[0].files[0];

    const [, ext] = originalFile.name.split('.');

    let name = $('#additive_info').val();

    if (name.includes('/')) {
      name = name.replace(/[\\:\*\?"<>\|]/g, '').replace(/\//g, '-');
    }

    const file = new File([originalFile], `${name}.${ext}`, {
      type: originalFile.type,
    });

    form.append('file_additive', file);

    $('#status').show();
    $('#preloader').show();

    $.ajax({
      type: 'POST',
      dataType: 'JSON',
      url: '/additions/store',
      enctype: 'multipart/form-data',
      processData: false,
      contentType: false,
      data: form,
      success: (res) => {
        console.log(res);
        $('input[name="file_additive"]').val('');

        appendAdditive([res.entity]);
        $('#form-commercial-proposal').append(
          '<input type="hidden" name="additions[]" value="' +
            res.entity.id +
            '" />'
        );
        $('#status').hide();
        $('#preloader').hide();
        $('#file-upload').modal('hide');
      },
      error: (err) => {
        console.log(err);
        $('input[name="file_additive"]').val('');
        $('#status').hide();
        $('#preloader').hide();
      },
    });
  }

  function formIsValid() {
    return !!$('#additive_info').val();
  }
}

function writeFileName(file) {
  const { name, size } = file;

  const formatedSize = calcFileSize(size);

  const fileText = `Arquivo carregado: ${name} (${formatedSize})`;

  $('#file_text').text(fileText);
}

function calcFileSize(size) {
  let fileSizeInBytes = size;

  const units = ['bytes', 'KB', 'MB', 'GB', 'TB'];
  let unitIndex = 0;

  while (fileSizeInBytes >= 1024 && unitIndex < units.length - 1) {
    fileSizeInBytes /= 1024;
    unitIndex++;
  }

  return fileSizeInBytes.toFixed(2) + ' ' + units[unitIndex];
}
