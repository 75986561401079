$(document).on('ready',function(){
    if(window.location.href.includes('/reports/monthly-billing')){
        init();
    }
});

function init(){
    var months = $('select[name="months[]"]').selectpicker('val');

    $.each($('.t-header table thead tr'), function(row,element){
        $.each($(element).find('th.months'), function(indexColumn,elementColumn){
            if(months && !months.includes(""+(indexColumn+1))){
                $(elementColumn).hide();
            } else{
                $(elementColumn).show();
            }
        });
    });

    $.each($('.t-body table tbody tr'), function(row,element){
        $.each($(element).find('td.months'), function(indexColumn,elementColumn){
            if(months && !months.includes(""+(indexColumn+1))){
                $(elementColumn).hide();
            } else{
                $(elementColumn).show();
            }
        });
    });

    resizeTable();
}

function resizeTable(){
    var adjustment = 6;
    var width = $('.t-body table').css('width');
    var height = $('.t-body table').css('height').replace('px','');
    $('.t-body').css('margin-top','-3px');
    
    if(height < 550){
        $('.t-header').css('overflow', 'hidden');
    }

    $('.t-body, .t-header').css('width', (parseFloat(width.replace('px',''))+ adjustment)+'px');
    $.each($('.t-body table tbody tr'), function(index,elementRow){
        $.each($(elementRow).find('td'), function(indexColumn,elementColumn){
            var widthColumn = $(elementColumn).css('width');
            $($('.t-header table thead tr th')[indexColumn]).css('width',widthColumn);
        });
    });

    $('.table-responsive').doubleScroll({
        resetOnWindowResize: true
    });
}