var cookieName = 'tab-budgeted-vs-actual-index';

$(document).ready(function () {
  if ($('#budgeted-vs-actual').length) {
    tabSingleton();
    tooglePeriodText();
    onChangePeriod();
    // $('select[name="years[]"]').attr('id','yearSelect');
    // $('select[name="months[]"]').attr('id','monthSelect');

    $('.tablesorter').tablesorter(getTableSorterOptions());
    $('.form-user-value').submit(formUserValueOptions);
    $('.btn-edit-user-value').click(showModalFormOptions);
    $('select[name="deleted_at"]').change(function () {
      allProjects();
      tooglePeriodText();
    });

    initSelectPickerFilters()
   
  }
  $('.table-details tr td:not(.has-btn-group)').click(getTableDetailsOptions);
});


function initSelectPickerFilters(){
  $('#monthSelect, #yearSelect').change(function(){
    $(`button[data-id="${$(this).attr('id')}"]`).attr('data-original-title', '');
  });

  setTimeout(() => {
    $('button[data-id="monthSelect"], button[data-id="yearSelect"]').tooltip({
      title: function() {
        return $(this).attr('data-custom-title');
      }
    });   

    $('select[name="deleted_at"]').change();

    $('#monthSelect, #yearSelect').change();
  },400)

}

function triggerToast(response) {
  iziToast.show({
    id: 'haduken',
    message:
      '<br>' +
      response.message +
      '<br><br> A página irá atualizar automaticamente',
    position: 'bottomRight',
    transitionIn: 'flipInX',
    transitionOut: 'flipOutX',
    progressBarColor: 'linear-gradient(to bottom, #24A830 0%, #fff 150%)',
    layout: 2,
    maxWidth: 500,
    timeout: 9990,
  });

  setTimeout(function () {
    location.reload();
  }, 10000);
}

function formExtraExpenseOptions(elem) {
  event.preventDefault();

  var data = serialize(elem);
  var id = $(elem).attr('data-id');

  $.ajax({
    url: $(elem).attr('data-route'),
    data: data,
    type: 'PUT',
    success: function (response) {
      $(elem)
        .parent()
        .children('form.form-update-extra-expenses')
        .css('display', 'none');
      $(elem)
        .parent()
        .children('span:nth-child(2)')
        .css('display', 'inline-block');

      $('.extra-expenses-' + id).each(function (key, item) {
        $(item).empty();
        $(item).html(data.extra_expenses);
      });

      triggerToast(response);
    },
    error: function (response) {
      alert('Valor incorreto, tente novamente');
    },
  });
}

function showModalFormOptions() {
  $('#modal-' + $(this).attr('id')).modal('toggle');
}

function formUserValueOptions(event) {
  event.preventDefault();

  var data = serialize(this);
  var id = $(this).attr('data-id');

  $.ajax({
    url: $(this).attr('data-route'),
    data: data,
    type: 'PUT',
    success: function (response) {
      $('a#user-' + id + ' .price').html(data.value);
      $('#modal-user-' + id).modal('toggle');

      triggerToast(response);
    },
    error: function (response) {
      alert('Valor incorreto, tente novamente');
    },
  });
}

function serialize(elem) {
  return $(elem)
    .serializeArray()
    .reduce(function (obj, item) {
      obj[item.name] = item.value;
      return obj;
    }, {});
}

function tabSingleton() {
  var linksTab = $("ul[role='tablist'] li[role='presentation'] a");
  var index = getTabIndex();

  $(linksTab[index]).tab('show');

  linksTab.on('shown.bs.tab', function () {
    $(this)
      .parent()
      .parent()
      .children()
      .each(function (key, elem) {
        if ($(elem).hasClass('active')) {
          Cookies.set(cookieName, key);
        }
      });
  });
}

function getTableSorterOptions() {
  return {
    sortReset: true,
  };
}

function getTableDetailsOptions() {
  var row = $(this).parent();
  var html = '<ul class="list-group">';
  var title = 'Detalhes de ';

  $.each(row[0].cells, function (key, item) {
    var thead = $(item)
      .parent()
      .parent()
      .parent()
      .children('thead')
      .children('tr');

    if (!$(thead[0].cells[key]).html()) {
      title += $(item).html();
      return true;
    }

    var divDescription = $(item).children('div');
    if (divDescription.hasClass('description')) {
      $.each(divDescription.children('div'), function (key, item) {
        html +=
          '<li class="list-group-item"><span style=\'color: #1f8128;\'>' +
          $(item).children('span.name').html() +
          ': </span>' +
          $(item).children('span.value').html() +
          '</li>';
      });

      return;
    }

    html +=
      '<li class="list-group-item"><span style=\'color: #1f8128;\'>' +
      $(thead[0].cells[key]).html() +
      ': </span>' +
      $(item).html() +
      '</li>';
  });

  html += '</ul>';

  if (title == 'Detalhes de ') {
    title += row[0].cells[0].innerHTML;
  }

  var modalBody = $('#modal-table-details .modal-body');
  var modalTitle = $('#modal-table-details .modal-title');

  modalBody.empty();
  modalTitle.empty();

  modalBody.append(html);
  modalTitle.append(title);

  $('a[data-href]').bind('click', function () {
    var url = new URL($(this).attr('data-href'));
    var path = url.pathname.split('/');
    var urlSecond = url.origin + '/images/invoices/' + path[path.length - 1];
    console.log('url', url.href);
    console.log('urlSecond', urlSecond);
    $.ajax({
      async: false,
      type: 'GET',
      url: url.href,
      dataType: 'json',
      error: function (err) {
        console.log(err);
        if (err.status == 200) {
          if (
            url.href.indexOf('png') > -1 ||
            url.href.indexOf('jpg') > -1 ||
            url.href.indexOf('jpeg') > -1
          ) {
            window.open('/see/image?img=' + url.href);
          } else {
            window.open(url.href);
          }
        } else {
          $.ajax({
            async: false,
            type: 'GET',
            url: urlSecond,
            dataType: 'json',
            error: function () {
              if (
                urlSecond.indexOf('png') > -1 ||
                urlSecond.indexOf('jpg') > -1 ||
                urlSecond.indexOf('jpeg') > -1
              ) {
                window.open('/see/image?img=' + urlSecond);
              } else {
                window.open(urlSecond);
              }
            },
          });
        }
      },
    });
  });

  $('#modal-table-details').modal('show');
}

function getTabIndex() {
  var index = Cookies.get(cookieName)
    ? Cookies.get('tab-budgeted-vs-actual-index')
    : 0;
  return index;
}

function showFormUpdateExtraExpenses(elem) {
  $(elem)
    .parent()
    .children('form.form-update-extra-expenses')
    .css('display', 'inline-block');
  applyMaskMoney('input#value, input.value', 'R$');
  $(elem).parent().children('span:nth-child(2)').css('display', 'none');
}

function allProjects() {
  if (!$('select[name="deleted_at"]').selectpicker('val')) {
    $("select[name='months[]']").selectpicker('val', '');
    $("select[name='years[]']").selectpicker('val', '');
  }
}

function tooglePeriodText() {
  if ($('select[name="deleted_at"]').selectpicker('val') === 'whereNull') {
    $('.period-create-text').css('display', 'block');
    return;
  }

  $('.period-create-text').css('display', 'none');
}

function onChangePeriod() {
  $('#monthSelect').on('change', function () {
    if (!$(this).val() || $(this).val().length === 0) {
      const text =
        $('select[name="deleted_at"]').selectpicker('val') === 'whereNotNull'
          ? 'Selecione mês de finalização'
          : 'Selecione o mês de início';
      setTimeout(function () {
        const button = $('button[data-id="monthSelect"]');
        span = button.find('span.filter-option.pull-left');
        span.text(text);
      }, 5);
    }
  });

  $('#yearSelect').on('change', function () {
    if (!$(this).val() || $(this).val().length === 0) {
      const text =
        $('select[name="deleted_at"]').selectpicker('val') === 'whereNotNull'
          ? 'Selecione ano de finalização'
          : 'Selecione o ano de início';
      setTimeout(function () {
        const button = $('button[data-id="yearSelect"]');
        span = button.find('span.filter-option.pull-left');
        span.text(text);
      }, 5);
    }
  });

  $('select[name="deleted_at"]').on('change', function () {
    if ($(this).val() === 'whereNotNull') {
      if (!$('#yearSelect').val() || $('#yearSelect').val().length === 0) {
        $('button[data-id="yearSelect"]')
          .find('span.filter-option.pull-left')
          .text('Selecione ano de finalização');
        $('button[data-id="yearSelect"]').attr('title','Selecione ano de finalização')
        .attr('data-title','Selecione ano de finalização');
      }

      if (!$('#monthSelect').val() || $('#monthSelect').val().length === 0) {
        $('button[data-id="monthSelect"]')
          .find('span.filter-option.pull-left')
          .text('Selecione mês de finalização');
          $('button[data-id="monthSelect"]').attr('title', 'Selecione mês de finalização')
          .attr('data-title', 'Selecione mês de finalização');
      }

      $('button[data-id="yearSelect"]').attr('title','Selecione ano de finalização')
      .attr('data-custom-title','Selecione ano de finalização');
      $('button[data-id="monthSelect"]').attr('title', 'Selecione mês de finalização')
        .attr('data-custom-title', 'Selecione mês de finalização');
    } else {
      if (!$('#yearSelect').val() || $('#yearSelect').val().length === 0) {
        $('button[data-id="yearSelect"]')
          .find('span.filter-option.pull-left')
          .text('Selecione o ano de início');
      }

      if (!$('#monthSelect').val() || $('#monthSelect').val().length === 0) {
        $('button[data-id="monthSelect"]')
        .find('span.filter-option.pull-left')
        .text('Selecione o mês de início');
      }

      $('button[data-id="yearSelect"]').attr('title','Selecione o mês de início')
      .attr('data-custom-title','Selecione o ano de início');
      $('button[data-id="monthSelect"]').attr('title', 'Selecione o mês de início')
        .attr('data-custom-title', 'Selecione o mês de início');
    }
  });
}
