if ($('#period').length && window.location.href.indexOf('projects') > -1) {
  $('#period').daterangepicker({
    opens: 'left',
    locale: {
      format: 'DD/MM/YYYY',
      cancelLabel: 'Limpar',
      applyLabel: 'Aplicar',
    },
    autoApply: true,
    autoUpdateInput: false,
  });

  $('.ranges').css('display', 'block');

  $('#period').on('apply.daterangepicker', function (ev, picker) {
    const startDate = picker.startDate.format('DD/MM/YYYY');
    const endDate = picker.endDate.format('DD/MM/YYYY');
    $(this).val(`${startDate}-${endDate}`);
  });

  $('#period').on('cancel.daterangepicker', function (ev, picker) {
    //do something, like clearing an input
    $('#period').val('');
  });
}
