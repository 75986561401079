$(document).ready(function () {
  //Date picker início da alocação
  $('#start').datetimepicker({
    format: 'L',
  });

  //Date picker fim da alocação da alocação

  if ($('#finish').val()) {
    $('#finish').datetimepicker({
      format: 'L',
      useCurrent: false,
    });
  } else {
    $('#finish').datetimepicker({
      format: 'L',
      useCurrent: false,
      minDate: moment().subtract(1, 'days'),
      disabledDates: [moment().subtract(1, 'days')],
    });
  }

  // A data de inicío será a data mínima
  $('#start').on('dp.change', function (e) {
    $('#finish').data('DateTimePicker').minDate(e.date);
  });

  // A data de fim será a data máxima
  $('#finish').on('dp.change', function (e) {
    $('#start').data('DateTimePicker').maxDate(e.date);
  });

  $('#value').mask('#.##0,00', {
    reverse: true,
  });
  $('#value_month').mask('#.##0,00', {
    reverse: true,
  });

  if ($('#office-form .has-error .help-block strong').html()) {
    $('#office-form').modal().show();
  }
});

const regex = /\/(\d+)\/edit$/;

$('.hours_calc_val').on('input', function () {
  let multiplier;

  if (window.location.href.includes('edit')) {
    const currentUrl = window.location.href;

    const [, id] = currentUrl.match(regex);

    multiplier = getMultiplier(id);
  } else {
    multiplier = getMultiplier('');
  }

  var calcValueMonth =
    parseFloat($(this).val().replace('.', '').replace(',', '.')) * multiplier;
  $('#value_month').val(
    calcValueMonth.toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
    })
  );
});

function getMultiplier(id) {
  switch (id) {
    case '22':
    case '20':
      return 220;
    case '31':
      return 150;
    default:
      return 200;
  }
}
