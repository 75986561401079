if($('#form-update-hours').length){

    $(".hours").off();
    $('#start_date').datetimepicker({
        format: 'L',
    });
    $("#form-update-hours #activities-project_id").bind('change', function(){
        var projectId = $(this).val();
        changeProject(projectId);
    });

    var hasErrors = false;

    $.each($('.help-block strong'), function(index,element){
        if($(element).html()){
            hasErrors= true;
        }
    });


    if($('#activities-project_id').val() && hasErrors){
        const projectId = $('#activities-project_id').val();
        changeProject(projectId);
    }

    
}

function changeProject(projectId){
    $.ajax({
        url: "/projects/" + projectId + '/members' + ($('#allProjectActivity').prop('checked') ? '?allCollaborators=true' : ''),

        success: function(result) {

            var select = $("#user_id");
            select.find('option').remove();
            $.each(result, function(key, value) {
                select.append('<option value=' + key + '>' + value + '</option>');
            });
            console.log(result);
            const userSelected = Object.keys(result).forEach(function(key){
                return key === parseInt($('#user_id').val() ? $('#user_id').val():'0');
            });

            if(!userSelected){
               $('#user_id').val('');
            }

            select.selectpicker('refresh');
            var params = new URLSearchParams(window.location.search.replace('?', ''));
            if (params.get('user_id')) {
                $('#user_id').selectpicker('val', params.get('user_id'));
            }
        }
    });

    $.ajax({
        url: '/projects/' + projectId + '/tasks',

        success: function(result) {
            var select = $("#task_id");
            select.find('option').remove();
            $.each(result, function(key, value) {
                select.append('<option value=' + key + '>' + value + '</option>');
            });

            const taskSelected = Object.keys(result).forEach(function(key){
                return key === parseInt($('#task_id').val() ? $('#task_id').val():'0');
            });

             if(!taskSelected){
                $('#task_id').val('');
             }

            select.selectpicker('refresh');
        }
    });

    $.getJSON('/projects/' + projectId + '/show', function(response) {
        var url = new URLSearchParams(window.location.search.replace('?', ''));
        var dateStart = moment(response.start, 'YYYY-MM-DD');

        if (!url.get('date')) {
            $('#start_date').data("DateTimePicker").minDate(dateStart.format('DD/MM/YYYY'));
        }
    });
}