if ($('.page-clients').length) {

    $('.btn-history').on('click', function() {
        $($('li a[href="#tab-history"]').parent()[0]).css('display', 'block');
        const element = this;
        $(element).children().css('display', 'block');

        $.ajax({
            type: 'GET',
            dataType: 'JSON',
            url: '/clients/' + $(element).attr('data-id') + '/histories',
            success: function(res) {
                $('#tab-history table tbody').html('');
                $.each(res['data'], function(index, value) {
                    $('#tab-history table tbody').append("<tr><td>" + value.group.cod + "</td><td>" + value.group.name + "</td></tr>")
                });

                $('li a[href="#tab-history"]').trigger('click');
                setTimeout(function() {
                    $(element).children().css('display', 'none');
                }, 400);
            },
            error: function(err) {
                if (err.status === 404) {
                    swal({
                        icon: 'warning',
                        title: 'Atenção',
                        text: 'Não foi encontrado histórico para o cliente especificado.'
                    });
                } else {
                    swal({
                        icon: 'error',
                        title: 'Ops',
                        text: 'Ocorreu um erro.'
                    });
                }

                $('li a[href="#tab-clients"]').trigger('click');
                $(element).children().css('display', 'none');
            }
        });

    });


    $('li a[href="#tab-clients"]').on('click', function() {
        $($('li a[href="#tab-history"]').parent()[0]).css('display', 'none');
    });
}