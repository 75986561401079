if($('#expense-delos-add').length || $('#expense-delos-update').length){

    $('#value').mask("#.##0,00", {reverse: true});

    $('#start').datetimepicker({
        format: 'L',
    });

    $('#finish').datetimepicker({
        format: 'L',
    });
 // A data de inicío será a data mínima
 $("#start").on("dp.change", function(e) {
    $('#finish').data("DateTimePicker").minDate(e.date);
});

// A data de fim será a data máxima
$("#finish").on("dp.change", function(e) {
    $('#start').data("DateTimePicker").maxDate(e.date);
});
}