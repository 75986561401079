$(document).ready(function(){
    if(window.location.href.indexOf('/groups/create') > -1){
        createGroups();
    }
});

function createGroups(){
    var url = new URL(window.location.href);
    var createCommercialProposal = url.searchParams.get('create_commercial_proposal');

    if(!createCommercialProposal){
        return;
    }

    var formUrl = $('#create-group-form').attr('action')+"?create_commercial_proposal="+createCommercialProposal;
    $('#create-group-form').attr('action',formUrl);
}