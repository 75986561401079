if ($('#payment-values').length !== 0) {
  $(document).ready(function () {
    initPaymentValues();
    $('#start, #finish').trigger('dp.change');
  });
}

function initPaymentValues() {
  $('#start, #finish').off('dp.change');

  $('#start').on('dp.change', function (e) {
    if (!$('#start').val() || !$('#date_payment').length) {
      return;
    }
    let startDate = e.date || moment($('#start').val(), 'DD/MM/Y');

    $('#finish').data('DateTimePicker').minDate(startDate);
    $('#date_payment').data('DateTimePicker').minDate(startDate);
  });

  $('#finish').on('dp.change', function (e) {
    if (!$('#finish').val() || !$('#date_payment').length) {
      return;
    }

    let finishDate = e.date || moment($('#finish').val(), 'DD/MM/Y');
    finishDate.set({ hour: 23, minute: 59, second: 59 });

    $('#start').data('DateTimePicker').maxDate(finishDate);
    $('#date_payment').data('DateTimePicker').maxDate(finishDate);
    
  });

  const initialDateValue = moment($('#start').val(), 'DD/MM/Y');

  if($('#date_payment').html()){
    $('#date_payment').data('DateTimePicker').date(initialDateValue);
  }


  getValuesPayments($('input[name="id"]').val());
  $('.save-values-payment').on('click', function () {
    const typePayment = $('#type_payment')
      .find('option[value="' + $('#type_payment').selectpicker('val') + '"]')
      .html();
    const value = $('#value_payment').val();
    const valueConverted = $('#value_converted').val();
    const description = $('#description_payment').val();
    const datePayment = $('#date_payment').val();
    const itemLength = $('#payment-values table tbody tr').length;

    const grid = document.createElement('tbody');
    $(grid).append(`
    <tr data-item="${itemLength + 1}">
    <td data-type-payment="${$('#type_payment').selectpicker('val')}">
        ${typePayment}
    </td>
    <td>
        ${description}
    </td>
    <td>
      ${value ? value.padEnd(value.length === 1 ? 4 : 5, ',00') : ''}
    </td>
    <td>
     ${
       valueConverted
         ? valueConverted.padEnd(value.length === 1 ? 4 : 5, ',00')
         : ''
     }
    </td>
    <td>
     ${datePayment}
    </td>
    <td>
        <button type="button" class="btn btn-danger"><span class="glyphicon glyphicon-trash" aria-hidden="true"></span></button>
    </td>
</tr>`);

    saveValuesPayment($(grid).find('tr')[0]);
    disabledDeleteEvent();
    enableDeleteEvent();
  });

  $('#value_payment').on('keyup', function () {
    calculateValueConverted();
  });

  $('#payment-values').on('hide.bs.modal', function () {
    const ignore = Boolean(!$('#start').val()  || !$('#finish').val());
    validateCalculation(0, ignore);
  });

  $('#type_payment').on('change', function () {
    calculateValueConverted();
  });

  $('#date_payment').val('');
}

function saveValuesPayment(rowValues) {
  const form = new FormData();
  const [typePayment, description, value, valueConverted, datePayment, action] =
    $(rowValues).find('td');
  let valid = true;

  const paymentDate = $(datePayment)
    .html()
    .replace(/(\r\n|\n|\r|\s)/g, '');

  if (!$('#finish').val()) {
    swal({
      icon: 'warning',
      title: 'Atenção!',
      text: 'Para cadastrar um pagamento a data de finalização deve ser informada!',
      button: 'Fechar',
    });

    return;
  }

  const finish_date = new Date(toUSADateString($('#finish').val()));
  const payment_date = new Date(toUSADateString(paymentDate));

  if (payment_date.getTime() > finish_date.getTime()) {
    swal({
      icon: 'error',
      title: 'Data de pagamento inválida!',
      text: 'Data de pagamento está fora do período de finalização do projeto',
      button: 'Fechar',
    });

    return;
  }

  form.append(
    'type_payment',
    String($(typePayment).data('type-payment')).replace(/(\r\n|\n|\r|\s)/g, '')
  );
  form.append('description_payment', $(description).html());
  form.append(
    'value',
    $(value)
      .html()
      .replace(/(\r\n|\n|\r|\s)/g, '')
  );
  if ($('input[name="id"]').val()) {
    form.append(
      'commercial_proposal_id',
      $('input[name="id"]')
        .val()
        .replace(/(\r\n|\n|\r|\s)/g, '')
    );
  }
  form.append(
    'value_converted',
    $(valueConverted)
      .html()
      .replace(/(\r\n|\n|\r|\s)/g, '')
      .replace('\\', '')
  );
  form.append('date_payment', paymentDate);
  form.append('_token', $('#payment-values input[name="_token"]').val());
  form.append('finish_date', $('#finish').val());

  $('.form-group').removeClass('has-error');
  $('.form-group span strong').html('');

  for (const col of $(rowValues).find('td')) {
    if (
      !$(col)
        .html()
        .replace(/(\r\n|\n|\r|\s)/g, '')
    ) {
      valid = false;
    }
  }

  if (valid) {
    validateCalculation(
      parseFloat(
        String(
          $(valueConverted)
            .html()
            .replace(/(\r\n|\n|\r|\s)/g, '')
            .replace('\\', '') || 0
        )
          .replace(/[.]/g, '')
          .replace(',', '.')
      ),
      true
    );
  }

  validateUniqueTypePayment(
    String($(typePayment).data('type-payment')).replace(/(\r\n|\n|\r|\s)/g, '')
  );

  $.ajax({
    type: 'POST',
    dataType: 'JSON',
    url: '/commercial/proposal/values-payment/save',
    data: form,
    enctype: 'multipart/form-data',
    contentType: false,
    processData: false,
    success: function (res) {
      $(action).find('button').attr('data-value-payment-id', res.entity.id);
      $('#form-commercial-proposal').append(
        `<input type='hidden' name='paymentvalues[]' value='${res.entity.id}' />`
      );
      $('#payment-values table tbody').append(rowValues);
      enableDeleteEvent();
      footerCalc();

      $('#payment-values form input[type="text"]').val('');
      $('#payment-values select').selectpicker('val', '');
    },
    error: function (err) {
      const { errors } = err.responseJSON;
      Object.keys(errors).forEach(function (attribute) {
        if (attribute === 'value') {
          $('#value_payment').parent().addClass('has-error');
          $('#value_payment ~ span strong').html(errors[attribute][0]);
          return;
        }

        if (attribute === 'type_payment') {
          $('#type_payment').parent().parent().addClass('has-error');
          $('#type_payment')
            .parent()
            .parent()
            .find('span strong')
            .html(errors[attribute][0]);
          return;
        }

        $(`#${attribute}`).parent().addClass('has-error');
        $(`#${attribute} ~ span strong`).html(errors[attribute][0]);
      });
    },
  });
}

function toUSADateString(date) {
  const [day, month, year] = date.split('/');

  return `${month}-${day}-${year}`;
}

function getValuesPayments(idCommercialProposal) {
  $('button[data-target="#payment-values"]').attr('disabled', 'disabled');
  $('button[data-target="#payment-values"] .circle-icon').show();

  $.ajax({
    type: 'GET',
    dataType: 'json',
    url: `/commercial/proposal/${idCommercialProposal}/values-payment`,
    success: function (res) {
      console.log(res);

      rendertable(res['data']);
      $('button[data-target="#payment-values"]').attr('disabled', null);
      $('button[data-target="#payment-values"] .circle-icon').hide();
    },
    error: function (err) {
      console.log(err);
      $('button[data-target="#payment-values"]').attr('disabled', null);
      $('button[data-target="#payment-values"] .circle-icon').hide();
    },
  });
}

function rendertable(rows) {
  $.each(rows, function (index, row) {
    $('#payment-values table tbody').append(`
        <tr data-item="${index}">
        <td data-type-payment="${row.type_payment}">
            ${row.type_payment_text}
        </td>
        <td>
            ${row.description_payment}
        </td>
        <td>
          ${row.value}
        </td>
        <td>
         ${row.value_converted}
        </td>
        <td>
         ${row.date_payment}
        </td>
        <td>
            <button type="button" data-value-payment-id="${row.id}" class="btn btn-danger"><span class="glyphicon glyphicon-trash" aria-hidden="true"></span></button>
        </td>
    </tr>`);
  });

  enableDeleteEvent();
  footerCalc();
}

function disabledDeleteEvent() {
  $('#payment-values table tbody tr td button.btn-danger').off();
}

function enableDeleteEvent() {
  $('#payment-values table tbody tr td button.btn-danger').on(
    'click',
    function () {
      const click = $(this);

      swal({
        icon: 'warning',
        title: 'Atenção',
        text: 'Deseja realmente deletar esse registro?',
        buttons: {
          sim: {
            text: 'Sim',
            className: 'swal-button--yes',
          },
          nao: {
            text: 'Não',
            className: 'swal-button--no',
          },
        },
      }).then((res) => {
        if (res === 'sim') {
          $(this).parent().parent().remove();
          deleteValuePayment($(click).data('value-payment-id'));
        }
      });
    }
  );
}

function deleteValuePayment(id) {
  if (!id) {
    return;
  }

  footerCalc();

  $.ajax({
    type: 'GET',
    dataType: 'json',
    url: `/commercial/proposal/values-payment/${id}/destroy`,
  });
}

function footerCalc() {
  const value = parseFloat(
    String($('#amount').val() || 0)
      .replace(/[.]/g, '')
      .replace(',', '.')
  );
  let total = 0;
  $.each($('#payment-values table tbody tr'), function () {
    total += parseFloat(
      String($($(this).find('td')[3]).html() || 0)
        .replace(/[.]/g, '')
        .replace(',', '.')
    );
  });

  if (!total) {
    $('#payment-values table tfoot').html('');
    return;
  }

  let row = `<tr>
  <th colspan='3'>Valor da Proposta: ${Intl.NumberFormat('pt-Br', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  }).format(value)}</th>
  <th colspan='3'>Total Pago: ${Intl.NumberFormat('pt-Br', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  }).format(total)}</th>
 </tr>`;

  $('#payment-values table tfoot').html(row);
}

function validateCalculation(addValue = 0, ignoreMinValue = false) {
  if( $('#payment-values form').css('display') === 'none') {
    return;
  }
  
  const value = parseFloat(
    String($('#amount').val() || 0)
      .replace(/[.]/g, '')
      .replace(',', '.')
  );
  let total = 0;
  $.each($('#payment-values table tbody tr'), function () {
    total += parseFloat(
      String($($(this).find('td')[3]).html() || 0)
        .replace(/[.]/g, '')
        .replace(',', '.')
    );
  });

  total += addValue;

  if (total > value) {
    swal({
      icon: 'error',
      title: 'Valor Excedido',
      text: 'Valor está acima do orçamento de R$ ' + $('#amount').val(),
      button: 'Fechar',
    });

    throw new Error('Valor Excedido');
  }

  if (total < value && !ignoreMinValue) {
    swal({
      icon: 'warning',
      title: 'Valor Abaixo do esperado',
      text: 'Valor está abaixo do orçamento de R$ ' + $('#amount').val(),
      button: 'Fechar',
    });

    throw new Error('Valor Abaixo de esperado');
  }
}

function calculateValueConverted() {
  const typePayment = $('#type_payment').val();

  if (typePayment != 1) {
    $('#value_converted').val($('#value_payment').val());
    return;
  }

  const value = parseFloat(
    $('#amount').val().replace(/[.]/g, '').replace(',', '.') || 0
  );
  const valuePayment = parseFloat(
    $('#value_payment').val().replace(/[.]/g, '').replace(',', '.') || 0
  );
  let calc = value * (valuePayment / 100);

  $('#value_converted').val(
    Intl.NumberFormat('pt-BR', {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    }).format(calc)
  );
}

function validateUniqueTypePayment(typePaymentParam) {
  const typePayment = $('tbody tr td[data-type-payment]').data('type-payment');

  if (!typePayment || !typePaymentParam) {
    return;
  }

  if (String(typePayment) !== typePaymentParam) {
    swal({
      icon: 'warning',
      title: 'Atenção',
      text: 'É permitido usar apenas um tipo de pagamento',
      button: 'Fechar',
    });

    throw new Error('Permitido apenas um tipo de pagamento');
  }
}
